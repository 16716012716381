<template>
    <footer>
        <b-row>
            <b-col>©2020 - <router-link to="/Informations-legales">Informations légales</router-link> - Développé par <a href="https://www.yannchapuis.fr" target="_blank">Yann CHAPUIS</a></b-col>
        </b-row>
        
    </footer>
</template>

<script>

export default {
    name: 'Footer'
}
</script>