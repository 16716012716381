<template>
    <div>
        <Videos></Videos>
    </div>
</template>

<script>
import Videos from './Videos/Videos';

export default {
    name: 'VideoPage',
    components: {
        Videos
    }
}
</script>