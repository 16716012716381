<template>
	<div id="studio">
    <div class="location-studio"><b-icon icon="geo-alt" class="contact-icon"></b-icon>  {{ info.adress }}<br></div>

	<div class="text-studio">{{ info.text }}</div>

<b-row>
	<b-col lg="4" SM="12" class="col-studio">
		{{ info.champs1 }}
	</b-col>
	<b-col lg="4" SM="12" class="col-studio">
		{{ info.champs2 }}
	</b-col>
	<b-col lg="4" SM="12" class="col-studio">
		{{ info.champs3 }}
	</b-col>
</b-row>
 




	</div>
</template>
<script>
import axios from 'axios';

export default {
    name: 'Studio',
	data () {
		return {
			info: null
		}
	},
	mounted () {
		axios
		.get(process.env.VUE_APP_API_URL+'/getStudio')
		.then(response => (this.info = response.data.data[0]))
	}
}
</script>