<template>
    <div id="dates">
        <Dates></Dates>
    </div>
</template>

<script>
import Dates from './Dates/Dates';
//import Artistes from './Main/Artistes';

export default {
    name: 'DatePage',
    components: {
        Dates
    }
}
</script>