<template>
	<div id="sonD" class="container-fluid">
		<div class="container">
			<div class="row">
				<div class="col text-center">
					<h2>Son</h2>
					<div class="divider-c"> <span></span></div>
				</div>
			</div>
		</div>

<b-carousel
		id="carousel-1"
		v-model="slide"
		:interval="4000"
		controls
		indicators
		fade
		background="#ababab"
		img-width="1024"
		img-height="480"
		style="text-shadow: 1px 1px 2px #333;"
		@sliding-start="onSlideStart"
		@sliding-end="onSlideEnd"
    >
      <!-- Slides with image only -->
		<b-carousel-slide img-src="http://www.motel-group.com/images/techson.jpg"></b-carousel-slide>

	</b-carousel>

		<b-row>
			<b-col lg="4" md="12" class="col-son">
				<div class="title-son">Son façade</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> Line Array LYNX 42000 Watts  (12 Têtes - 6 Subs)</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> Console Numérique  DIGICO  SD8</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> FX  /  LEXICON - TC.</div>
			</b-col>
			<b-col lg="4" md="12" class="col-son">
				<div class="title-son">Son retour</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> Console Numérique  YAMAHA  LS9 - 32</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 6 systèmes EARS MONITORS   SENNHEISER G3</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 4 wedges  APOGEE + PROC APOGEE</div>
			</b-col>
			<b-col lg="4" md="12" class="col-son">
				<div class="title-son">Micros</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> SHURE  (Beta 91 - Beta 52 - Beta 98 - SM58 - SM57 - UHF Beta 58)</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> AKG  (C 414 - SE 300 - C 415 - C 1000)</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> SENNHEISER  (MD 421 - 906 - 609)</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> BEYER  (TGX 80 - TG D52D)</div>
			</b-col>
		</b-row>
	</div>
</template>
<script>
export default {
	name: 'SonD',
	data() {
      return {
        slide: 0,
        sliding: null
      }
    },
}
</script>