<template>
    <div>
        <Photos></Photos>
    </div>
</template>

<script>

import Photos from './Photos/Photos';
export default {
    name: 'PhotoPage',
    components: {
        Photos
    }
}
</script>