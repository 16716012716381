<template>
    <div>
        <Son></Son>
        <Lumiere></Lumiere>
        <Transport></Transport>
    </div>
</template>

<script>

import Son from './Technique/Son';
import Lumiere from './Technique/Lumiere';
import Transport from './Technique/Transport';

export default {
    name: 'TechniquePage',
    components: {
        Son,
        Lumiere,
        Transport
    }
}
</script>