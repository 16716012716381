<template>
	<div id="slider" class="container-fluid">
		<div class="container">
			<div class="row">
				<div class="col text-center">
					<h2>Studio d'enregistrement</h2>
					<div class="divider-c"> <span></span></div>
				</div>
			</div>
		</div>
    <b-carousel
		id="carousel-1"
		v-model="slide"
		:interval="4000"
		controls
		indicators
		fade
		background="#ababab"
		img-width="1024"
		img-height="480"
		style="text-shadow: 1px 1px 2px #333;"
    >
      <!-- Slides with image only -->
		<b-carousel-slide v-for="data in info" :key="data.id" v-bind:img-src="url_admin+'/images/_sliders/'+ data.url_image"></b-carousel-slide>


	</b-carousel>
	</div>
</template>
<script>
import axios from 'axios';

export default {
    name: 'StudioSlider',
	data () {
		return {
			info: null,
			url_admin: process.env.VUE_APP_ADMIN_URL,
			slide: 0,
			sliding: null
		}
	},
	mounted () {
		axios
		// .get(API+'/getLastDates')
		.get(process.env.VUE_APP_API_URL+'/showFilesStudio')
		.then(response => (this.info = response.data.data))
	}
}
</script>