import Vue from 'vue';
import VueRouter from 'vue-router';
import { BootstrapVue, IconsPlugin, CarouselPlugin } from 'bootstrap-vue';
import App from './App.vue';
import $ from 'jquery';
import './assets/style/custom.scss';
import { router } from './router';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VideoBackground from 'vue-responsive-video-background-player';
import AOS from 'aos';
import 'aos/dist/aos.css';


var url_api = 'https://apimotel.test/api';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(CarouselPlugin)
Vue.use(VueRouter);
Vue.use(url_api);



Vue.use($);

Vue.component('video-background', VideoBackground);

new Vue({
  created () {
    AOS.init()
  },
  router,
  render: h => h(App),
}).$mount('#app')