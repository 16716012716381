<template>
	<div v-if="video" id="VideoGallery" class="container-fluid">
		<div class="container">
			<div class="row">
				<div class="col text-center video-responsive">
					<iframe id="video_main" v-bind:src="'https://www.youtube.com/embed/'+ video[0].url_video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				</div>
			</div>
		</div>
		<div class="container">
			<div class="row">
				<div class="col text-center">
					<router-link to="/Videos" class="btn text-center"><span class="btn-text">Voir toutes les vidéos</span></router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'VideoGallery',
	data () {
		return {
			video: null
		}
	},
	mounted () {
		axios
		.get(process.env.VUE_APP_API_URL+'/getMainVideo')
		.then(response => (this.video = response.data.data))
	}
}
</script>