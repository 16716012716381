import VueRouter from 'vue-router';
// Pages
import MainPage from './components/pages/MainPage';
import DatePage from './components/pages/DatePage';
import PhotoPage from './components/pages/PhotoPage';
import VideoPage from './components/pages/VideoPage';
import TechniquePage from './components/pages/TechniquePage';
import TechniqueDPage from './components/pages/TechniqueDPage';
import StudioPage from './components/pages/StudioPage';
import ContactPage from './components/pages/ContactPage';
import LegalePage from './components/pages/LegalePage';

export const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    scrollBehavior() {
      return { x: 0, y: 0 };
    },
    routes: [
      { path: '/', component: MainPage },
      { path: '/Dates', component: DatePage },
      { path: '/Photos', component: PhotoPage },
      { path: '/Videos', component: VideoPage },
      { path: '/Technique', component: TechniquePage },
      { path: '/Studio', component: StudioPage },
      { path: '/Contact', component: ContactPage },
      { path: '/TechniqueD', component: TechniqueDPage },
      { path: '/Informations-legales', component: LegalePage },
    ],
});