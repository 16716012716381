<template>
  <div id="contact-form">

    <div class="text-center contact-intro-form">
        Besoin de renseignement ? <br>
        N'hésitez pas à nous contacter !
    </div>

    <b-form @submit="onSubmit" v-if="show">
      <b-form-group
        id="input-group-1"
        class="form__group field"
      >
        <b-form-input
          id="input-1"
          v-model="form.email"
          class="form__field"
          type="email"
          required
        ></b-form-input>
        <label for="email" class="form__label">Adresse email</label>
      </b-form-group>

      <b-form-group id="input-group-2" class="form__group field">
        <b-form-input
          id="input-2"
          v-model="form.name"
          class="form__field"
          required
        ></b-form-input>
          <label for="name" class="form__label">Name</label>
      </b-form-group>

      <b-form-group id="input-group-3" class="form__group field">
        <b-form-input
          id="input-2"
          type="number"
          min="0000000000"
          max="9999999999"
          v-model="form.telephone"
          class="form__field"
          required
        ></b-form-input>
        <label for="telephone" class="form__label">Téléphone</label>
      </b-form-group>


      <b-form-group id="input-group-4" class="form__group field">
        <b-form-select
          id="input-3"
          v-model="form.subject"
          class="form__field"
          :options="subjects"
          required
        ></b-form-select>
        <label for="subject" class="form__label">Sujet</label>
      </b-form-group>

      <b-form-group id="input-group-4" class="form__group field">
        <b-form-textarea
        id="textarea"
        v-model="form.text"
        class="form__field"
        rows="5"
        max-rows="15"
        required
        ></b-form-textarea>
        <label for="text" class="form__label">Message</label>
      </b-form-group>
        <b-row class="justify-content-md-center">
            <b-button type="submit" class="btn"><span class="btn-text">Envoyer</span></b-button>
        </b-row>
    </b-form>

    <b-row class="justify-content-md-center">
        <b-col class="follow-us">Suivez-nous !</b-col>
    </b-row>
    <b-row class="justify-content-md-center">
      <b-col class="text-right"><a href="https://www.facebook.com/Motel-Group-190149384444866/" target="_blank"><img v-bind:src="'/img/social/facebook.svg'" class="social-contact"></a></b-col>
      <b-col class="text-left"><a href="https://www.youtube.com/channel/UCqr2EeyiFki_sNiVbqpCoqw" target="_blank"><img v-bind:src="'/img/social/youtube.svg'" class="social-contact"></a></b-col>							
    </b-row>
  </div>
</template>

<script>
export default {
    name: 'ContactForm',
    data() {
      return {
        form: {
          email: '',
          name: '',
          telephone: '',
          text: '',
          subject: null
        },
        subjects: [{ text: '', value: 'Autre' }, 'Demande d\'information', 'Dates', 'Autre'],
        show: true
      }
    },
    methods: {
      onSubmit(evt) {
        evt.preventDefault()
        alert(JSON.stringify(this.form))
      }
    }
  }
</script>