  
<template>
	<div id="transport" class="container-fluid">
		<div class="container">
			<div class="row">
				<div class="col text-center">
					<h2>Transport</h2>
					<div class="divider-c"> <span></span></div>
				</div>
			</div>
		</div>
		<b-row>
			<b-col lg="6" v-for="data in showTechniqueTransport" :key="data.id" class="col-transport">
				{{data.text}}
			</b-col>
		</b-row>

    <b-carousel
		id="carousel-1"
		v-model="slide"
		:interval="4000"
		controls
		indicators
		fade
		background="#ababab"
		img-width="1024"
		img-height="480"
		style="text-shadow: 1px 1px 2px #333;"
    >
      <!-- Slides with image only -->
	<b-carousel-slide v-for="dataTs in infoFilesT" :key="dataTs.id" v-bind:img-src="url_admin+'/images/_sliders/'+ dataTs.url_image"></b-carousel-slide>

	</b-carousel>



	</div>
</template>
<script>
import axios from 'axios';
export default {
    name: 'Transport',
	data () {
		return {
			showTechniqueTransport: null,
			infoFilesT: null,
			url_admin: process.env.VUE_APP_ADMIN_URL,
			slide: 0,
			sliding: null
		}
	},
	mounted () {
		axios.all([
			axios.get(process.env.VUE_APP_API_URL+'/showTechniqueTransport'), 
			axios.get(process.env.VUE_APP_API_URL+'/showFilesTransport'), 
		])
		.then(responseArr => {
			this.showTechniqueTransport = responseArr[0].data.data;
			this.infoFilesT = responseArr[1].data.data;
		});
	},
}
</script>