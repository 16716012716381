<template>
	<div id="artistes" class="container-fluid">
		<div class="container">
			<div class="row">
				<div class="col text-center">
					<h2>Artistes</h2>
					<div class="divider-c"> <span></span></div>
				</div>
			</div>
		</div>

		<div class="container">
			<b-row class="text-center">
				<!-- Artiste 1 -->
				<b-col class="example-2 card" lg="4" md="6" sm="12"
				v-for="artistes in artiste"
				:key="artistes.id"
				>
					<div class="wrapper" v-bind:style="'background: url('+ url_admin +'/images/artistes/'+ artistes.url_image +') center/cover no-repeat;'">
						<div class="data">
							<div class="content">
								<h1 class="name">{{ artistes.firstname }} <br> {{ artistes.name }}</h1>
									<div class="social">
										<a v-if="artistes.facebook !== null" :href="artistes.facebook" target="_blank"><img v-bind:src="'/img/social/svg/043-facebook-1.svg'" class="social-artistes border-right"></a>
										<a v-if="artistes.youtube !== null" :href="artistes.youtube" target="_blank"><img v-bind:src="'/img/social/svg/002-youtube.svg'" class="social-artistes border-right"></a>
										<a v-if="artistes.instagram !== null" :href="artistes.instagram" target="_blank"><img v-bind:src="'/img/social/svg/034-instagram.svg'" class="social-artistes border-right"></a>
										<a v-if="artistes.whatsapp !== null" :href="artistes.whatsapp" target="_blank"><img v-bind:src="'/img/social/svg/007-whatsapp.svg'" class="social-artistes"></a>
										<a v-if="artistes.facebook == null && artistes.youtube == null && artistes.instagram == null && artistes.whatsapp == null" :href="'https://www.youtube.com/channel/UCqr2EeyiFki_sNiVbqpCoqw'" target="_blank"><img v-bind:src="'/img/social/svg/002-youtube.svg'" class="social-artistes"></a>
									</div>
							</div>
						</div>
					</div>
				</b-col>
			</b-row>
		</div>

	</div>

</template>

<script>
import axios from 'axios';

export default {
    name: 'Artistes',
	data () {
		return {
			artiste: null,
			url_admin: process.env.VUE_APP_ADMIN_URL
		}
	},
	mounted () {
		axios
		.get(process.env.VUE_APP_API_URL+'/getArtistes')
		.then(response => (this.artiste = response.data.data))
	}
}
</script>