<template>
    <div>
        <SonD></SonD>
        <LumiereD></LumiereD>
        <Transport></Transport>
    </div>
</template>

<script>

import SonD from './Technique/SonD';
import LumiereD from './Technique/LumiereD';
import Transport from './Technique/Transport';

export default {
    name: 'TechniqueDPage',
    components: {
        SonD,
        LumiereD,
        Transport
    }
}
</script>