<template>
	<div id="lumiere" class="container-fluid">
		<div class="container">
			<div class="row">
				<div class="col text-center">
					<h2>Lumière</h2>
					<div class="divider-c"> <span></span></div>
				</div>
			</div>
		</div>
<!--
    <b-carousel
		id="carousel-1"
		v-model="slide"
		:interval="4000"
		controls
		indicators
		fade
		background="#ababab"
		img-width="1024"
		img-height="480"
		style="text-shadow: 1px 1px 2px #333;"
		@sliding-start="onSlideStart"
		@sliding-end="onSlideEnd"
    >
		<b-carousel-slide img-src="http://www.motel-group.com/images/techson.jpg"></b-carousel-slide>

	</b-carousel>

		<b-row>
			<b-col lg="4" md="12" class="col-son">
				<div class="title-son">Lumières générales</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> Console CHAMSYS MAGIC Q</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 12 Lyres BEAM 5R PROJLIGHT</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 14  Wash  MARTIN  MAC  600</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 2  MARTIN  MAC 250+</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 2  Strobs  MARTIN  ATOMIC  3000</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 6  SUNSTRIPS  T10</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 2  Machines à fumée  MAGNUM  PRO 2000</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 1  Bloc GALATEC  12 X 3 K</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 2  Demux MA LIGHTING (60 Canaux)</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 1  Splitter Dmx  ELITE</div>
			</b-col>
			<b-col lg="4" md="12" class="col-son">
				<div class="title-son">ECRAN VIDEO LEDS</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> Ecran STARWAY 16 m2</div>
			</b-col>
			<b-col lg="4" md="12" class="col-son">
				<div class="title-son">Micros</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 1 Grill   13m x 10m  (400 Carré)</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 4 Towers  Hauteur  8m</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 4  Moteurs Verlinde 1 tonne</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 2  Genies Towers  ST 24</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 8 m  Structures  Triangle  300</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 12  Praticables  2 x 1m</div>
			</b-col>
		</b-row>-->

		<b-row align-v="center">
			<b-col lg="6" md="12" class="slider-mobile">
				<b-carousel
					id="carousel-1"
					v-model="slide"
					:interval="4000"
					controls
					indicators
					fade
					background="#ababab"
					img-width="1024"
					img-height="480"
					style="text-shadow: 1px 1px 2px #333;"
				>
				<!-- Slides with image only -->
					<b-carousel-slide v-for="dataLs in infoFilesL" :key="dataLs.id" v-bind:img-src="url_admin+'/images/_sliders/'+ dataLs.url_image"></b-carousel-slide>

				</b-carousel>
			</b-col>
			<b-col lg="6" md="12">
				<div class="light-column">
					<div class="title-son">LUMIÈRES GÉNÉRALES</div>
					<b-row class="row-cat">
						<b-col lg="6" md="6" v-for="dataG in showTechniqueLumiereG" :key="dataG.id" class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> {{dataG.text}}</b-col>
					</b-row>
				</div>
				<div class="light-column">
					<div class="title-son">ECRAN VIDEO LEDS</div>
					<b-row class="row-cat">
						<b-col lg="6" md="6" v-for="dataE in showTechniqueLumiereE" :key="dataE.id" class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> {{dataE.text}}</b-col>
					</b-row>
				</div>
				<div class="light-column">
					<div class="title-son">STRUCTURES</div>
					<b-row class="row-cat">
						<b-col lg="6" md="6" v-for="dataM in showTechniqueLumiereM" :key="dataM.id" class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> {{dataM.text}}</b-col>
					</b-row>
				</div>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import axios from 'axios';

export default {
    name: 'Lumiere',
	data () {
		return {
			showTechniqueLumiereG: null,
			showTechniqueLumiereE: null,
			showTechniqueLumiereM: null,
			infoFilesL: null,
			url_admin: process.env.VUE_APP_ADMIN_URL,
			slide: 0,
			sliding: null
		}
	},
	mounted () {
		axios.all([
			axios.get(process.env.VUE_APP_API_URL+'/showTechniqueLumiereG'), 
			axios.get(process.env.VUE_APP_API_URL+'/showTechniqueLumiereE'), 
			axios.get(process.env.VUE_APP_API_URL+'/showTechniqueLumiereM'), 
			axios.get(process.env.VUE_APP_API_URL+'/showFilesLumiere'),
		])
		.then(responseArr => {
			this.showTechniqueLumiereG = responseArr[0].data.data;
			this.showTechniqueLumiereE = responseArr[1].data.data;
			this.showTechniqueLumiereM = responseArr[2].data.data;
			this.infoFilesL = responseArr[3].data.data;
		});
	},
}


</script>