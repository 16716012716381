<template>
    <div id="main-video">
        <iframe width="420" height="315" src="https://www.youtube.com/embed/4Hv3PDbqoVk?autoplay=0&controls=0&disablekb=0&iv_load_policy=3&loop=1&modestbranding=1&fs=0&autohide=1&showinfo=0" frameborder="0" allowfullscreen></iframe>
        <img id="bottom-border-video" v-bind:src="'/img/border-bottom.png'">
    </div>
</template>
<script>
export default {
    name: 'Video'
}
</script>