<!-- https://vuejsexamples.com/a-simple-image-video-lightbox-component-for-vue-js/ --> 
<template>
	<div id="video" class="container-fluid">
		<div class="container">
			<div class="row">
				<div class="col text-center">
					<h2>Vidéos</h2>
					<div class="divider-c"> <span></span></div>
				</div>
			</div>
		</div>
    <CoolLightBox 
      :items="items" 
      :index="index"
      @close="index = null">
    </CoolLightBox>
	<b-container class="bv-example-row" style="margin-bottom: 100px;">
		<b-row class="justify-content-md-center">
			<b-col class="main-video" lg="6" md="6">
				<iframe class="video" v-bind:src="'https://www.youtube.com/embed/'+info.url_video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				<div class="video-title">{{info.title}}<br></div>
				<div class="video-description">{{info.description}}</div>
			</b-col>
		</b-row>
	</b-container>
    <b-row class="images-wrapper">
      <b-col
		lg="3"
		md="4"
		sm="12"
        class="video_list"
        v-for="(image, imageIndex) in items"
        :key="imageIndex"
        @click="index = imageIndex"
        
      >
		<img class="video_image_list" v-bind:src="image.thumb" >
		<div class="video_title_list text-center">{{ image.title }}</div>
		</b-col>
    </b-row>


	</div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import axios from 'axios';

export default {
	name: 'Videos',
	components: {
		CoolLightBox,
	},
	data () {
		return {
			info: null,
			url_admin: process.env.VUE_APP_ADMIN_URL,
			items: null,
			index: null
		}
	},
	mounted () {
		axios.all([
			axios.get(process.env.VUE_APP_API_URL+'/getMainVideo'), 
			axios.get(process.env.VUE_APP_API_URL+'/getVideos'), 
		])
		.then(responseArr => {
			this.info = responseArr[0].data.data[0];
			this.items = responseArr[1].data.data;
		});
	},

}
</script>