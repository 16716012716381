<template>
	<div id="son" class="container-fluid">
		<div class="container">
			<div class="row">
				<div class="col text-center">
					<h2>Son</h2>
					<div class="divider-c"> <span></span></div>
				</div>
			</div>
		</div>

		<b-row align-v="center" class="order_mobile">
			<b-col lg="6" md="12">
				<div class="light-column">
					<div class="title-son">Son façade</div>
					<b-row class="row-cat">
						<b-col lg="6" md="6" v-for="dataF in infoSonF" :key="dataF.id" class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> {{ dataF.text }}</b-col>
					</b-row>
				</div>
				<div class="light-column">
					<div class="title-son">Son retour</div>
					<b-row class="row-cat">
						<b-col lg="6" md="6" v-for="dataR in infoSonR" :key="dataR.id" class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> {{ dataR.text }}</b-col>
					</b-row>
				</div>
				<div class="light-column">
					<div class="title-son">Micros</div>
					<b-row class="row-cat">
						<b-col lg="6" md="6" v-for="dataM in infoSonM" :key="dataM.id" class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> {{ dataM.text }}</b-col>
					</b-row>
				</div>
			</b-col>
			<b-col lg="6" md="12" class="slider-mobile">
				<b-carousel
					id="carousel-1"
					v-model="slide"
					:interval="4000"
					controls
					indicators
					fade
					background="#ababab"
					img-width="1024"
					img-height="480"
					style="text-shadow: 1px 1px 2px #333;"
				>
				<!-- Slides with image only -->
					<b-carousel-slide v-for="dataFs in infoFilesS" :key="dataFs.id" v-bind:img-src="url_admin+'/images/_sliders/'+ dataFs.url_image"></b-carousel-slide>

				</b-carousel>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import axios from 'axios';

export default {
    name: 'Son',
	data () {
		return {
			infoSonF: null,
			infoSonR: null,
			infoSonM: null,
			infoFilesS: null,
			url_admin: process.env.VUE_APP_ADMIN_URL,
			slide: 0,
			sliding: null
		}
	},
	mounted () {
		axios.all([
			axios.get(process.env.VUE_APP_API_URL+'/showTechniqueSonF'), 
			axios.get(process.env.VUE_APP_API_URL+'/showTechniqueSonR'), 
			axios.get(process.env.VUE_APP_API_URL+'/showTechniqueSonM'),
			axios.get(process.env.VUE_APP_API_URL+'/showFilesSon'),
		])
		.then(responseArr => {
			this.infoSonF = responseArr[0].data.data;
			this.infoSonR = responseArr[1].data.data;
			this.infoSonM = responseArr[2].data.data;
			this.infoFilesS = responseArr[3].data.data;
		});
	},
}
</script>