<template>
  <div id="contact">

    <div class="text-center contact-intro">
        Contactez-nous !
    </div>

    <b-row class="justify-content-md-center">
        <b-col lg="4" sm="12" class="text-center contact-telephone">
            <div class="h2 mb-0">
                <b-icon icon="telephone" class="contact-icon"></b-icon>
            </div>
            <div class="contact-title">Téléphone</div>
            <div class="contact-content">{{ info.telephone }}</div>
        </b-col>
        <b-col lg="4" sm="12" class="text-center contact-adress">
            <div class="h2 mb-0">
                <b-icon icon="geo-alt" class="contact-icon"></b-icon>
            </div>
            <div class="contact-title">Adresse</div>
            <div class="contact-content">
                <b>GROUPE MOTEL</b><br>
                {{ info.adress }}
                
            </div>
        </b-col>
        <b-col lg="4" sm="12" class="text-center contact-mail">
            <div class="h2 mb-0">
                <b-icon icon="envelope" class="contact-icon"></b-icon>
            </div>
            <div class="contact-title">Email</div>
            <div class="contact-content">{{ info.email }}</div>
        </b-col>
    </b-row>

  </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'ContactInfo',
	data () {
		return {
			info: null
		}
	},
	mounted () {
		axios
		// .get(API+'/getLastDates')
		.get(process.env.VUE_APP_API_URL+'/getContact')
		.then(response => (this.info = response.data.data[0]))
	}
}
</script>