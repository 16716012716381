<template>
    <div>
        <Video></Video>
        <Biographie></Biographie>
        <TransitionBio></TransitionBio>
        <Dates></Dates>
        <TransitionDates></TransitionDates>
        <VideoGallery></VideoGallery>
        <TransitionVideo></TransitionVideo>
        <Artistes></Artistes>
    </div>
</template>

<script>
import Video from './Main/Video';
import Biographie from './Main/Biographie';
import TransitionBio from './Main/TransitionBio';
import Dates from './Main/Dates';
import TransitionDates from './Main/TransitionDates';
import VideoGallery from './Main/VideoGallery';
import TransitionVideo from './Main/TransitionVideo';
import Artistes from './Main/Artistes';
//import Artistes from './Main/Artistes';

export default {
    name: 'MainPage',
    components: {
        Video,
        Biographie,
        TransitionBio,
        Dates,
        TransitionDates,
        VideoGallery,
        TransitionVideo,
        Artistes
    }
}
</script>