<template>
    <div>
        <ContactInfo></ContactInfo>
        <TransitionContact></TransitionContact>
        <ContactForm></ContactForm>
    </div>
</template>

<script>
import ContactInfo from './Contact/ContactInfo';
import TransitionContact from './Contact/TransitionContact';
import ContactForm from './Contact/ContactForm';

export default {
    name: 'ContactPage',
    components: {
        ContactInfo,
        TransitionContact,
        ContactForm
    }
}
</script>