<template>
    <div>
        <StudioSlider></StudioSlider>
        <Studio></Studio>
    </div>
</template>

<script>

import StudioSlider from './Studio/Slider';
import Studio from './Studio/Studio';

export default {
    name: 'StudioPage',
    components: {
        StudioSlider,
        Studio
    }
}
</script>