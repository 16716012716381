<template>
	<div id="photos" class="container-fluid">
		<div class="container">
			<div class="row">
				<div class="col text-center">
					<h2>Photos</h2>
					<div class="divider-c"> <span></span></div>
				</div>
			</div>
		</div>

		<CoolLightBox 
		v-if="projects && currentFilter === 'TOUTES'"
		:items="projects" 
		:index="index"
		@close="index = null">
		</CoolLightBox>

		<CoolLightBox 
		v-if="projects && currentFilter === 'MUSICIENS'"
		:items="evenMusiciens" 
		:index="index"
		@close="index = null">
		</CoolLightBox>

		<CoolLightBox 
		v-if="projects && currentFilter === 'SCENE'"
		:items="evenScene" 
		:index="index"
		@close="index = null">
		</CoolLightBox>

		<div class="title-container">
			<div class="filters">
				<span class="filter" v-bind:class="{ active: currentFilter === 'TOUTES' }" v-on:click="setFilter('TOUTES')">Toutes</span>
				<span class="filter" v-bind:class="{ active: currentFilter === 'MUSICIENS' }" v-on:click="setFilter('MUSICIENS')">Musiciens</span>
				<span class="filter" v-bind:class="{ active: currentFilter === 'SCENE' }" v-on:click="setFilter('SCENE')">Scène</span>
			</div>
		</div>

		<transition-group class="row images-wrapper projects" name="projects" style="margin:0;">

				<!-- eslint-disable -->
					<!-- <div
						class="image_list col-lg-3 col-md-4 col-sm-12"
						v-for="(project, imageIndex) in projects"
						v-if="currentFilter === project.categorie || currentFilter === 'TOUTES'" 
						:key="imageIndex"
						@click="index = imageIndex"
						:style="{ backgroundImage: 'url('+project.thumb+')', cursor: 'zoom-in' }"
					></div>  -->
					<div
						class="image_list col-lg-3 col-md-4 col-sm-12"
						v-for="(project, imageIndex) in projects"
						v-if="currentFilter === 'TOUTES'"
						:key="imageIndex"
						@click="index = imageIndex"
						:style="{ backgroundImage: 'url('+project.thumb+')', cursor: 'zoom-in' }"
					></div>
					<div
						class="image_list col-lg-3 col-md-4 col-sm-12"
						v-for="(project, imageIndex) in evenMusiciens"
						v-if="currentFilter === 'MUSICIENS'"
						:key="imageIndex"
						@click="index = imageIndex"
						:style="{ backgroundImage: 'url('+project.thumb+')', cursor: 'zoom-in' }"
					></div>
					<div
						class="image_list col-lg-3 col-md-4 col-sm-12"
						v-for="(project, imageIndex) in evenScene"
						v-if="currentFilter === 'SCENE'"
						:key="imageIndex"
						@click="index = imageIndex"
						:style="{ backgroundImage: 'url('+project.thumb+')', cursor: 'zoom-in' }"
					></div> 
				<!-- eslint-enable -->
				
		</transition-group>	
		


	</div>
</template>
<script>
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import axios from 'axios';

export default {
	name: 'Photos',
	components: {
		CoolLightBox,
	},
	data: function () {
		return {
			currentFilter: 'TOUTES',
			projects: null,
			url_admin: process.env.VUE_APP_ADMIN_URL,
			index: null
		}
	},

	mounted () {
		axios
		.get(process.env.VUE_APP_API_URL+'/getPhotos')
		.then(response => (this.projects = response.data.data))
	},

	methods: {
		setFilter: function(filter) {
			this.currentFilter = filter;
		}
	},

	computed: {
		evenMusiciens: function () {
			return this.projects.filter(function (project) {
				return project.categorie === 'MUSICIENS';
			})
		},
		evenScene: function () {
			return this.projects.filter(function (project) {
				return project.categorie === 'SCENE';
			})
		}
	}
};

</script>