<template>
	<div id="lumiereD" class="container-fluid">
		<div class="container">
			<div class="row">
				<div class="col text-center">
					<h2>Lumière</h2>
					<div class="divider-c"> <span></span></div>
				</div>
			</div>
		</div>
    <b-carousel
		id="carousel-1"
		v-model="slide"
		:interval="4000"
		controls
		indicators
		fade
		background="#ababab"
		img-width="1024"
		img-height="480"
		style="text-shadow: 1px 1px 2px #333;"
		@sliding-start="onSlideStart"
		@sliding-end="onSlideEnd"
    >
		<b-carousel-slide img-src="http://www.motel-group.com/images/techson.jpg"></b-carousel-slide>

	</b-carousel>

		<b-row>
			<b-col lg="4" md="12" class="col-son">
				<div class="title-son">Lumières générales</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> Console CHAMSYS MAGIC Q</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 12 Lyres BEAM 5R PROJLIGHT</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 14  Wash  MARTIN  MAC  600</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 2  MARTIN  MAC 250+</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 2  Strobs  MARTIN  ATOMIC  3000</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 6  SUNSTRIPS  T10</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 2  Machines à fumée  MAGNUM  PRO 2000</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 1  Bloc GALATEC  12 X 3 K</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 2  Demux MA LIGHTING (60 Canaux)</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 1  Splitter Dmx  ELITE</div>
			</b-col>
			<b-col lg="4" md="12" class="col-son">
				<div class="title-son">ECRAN VIDEO LEDS</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> Ecran STARWAY 16 m2</div>
			</b-col>
			<b-col lg="4" md="12" class="col-son">
				<div class="title-son">Micros</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 1 Grill   13m x 10m  (400 Carré)</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 4 Towers  Hauteur  8m</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 4  Moteurs Verlinde 1 tonne</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 2  Genies Towers  ST 24</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 8 m  Structures  Triangle  300</div>
					<div class="content-son"><b-icon icon="check" class="contact-icon"></b-icon> 12  Praticables  2 x 1m</div>
			</b-col>
		</b-row>
	</div>
</template>
<script>
export default {
	name: 'LumiereD',
	data() {
      return {
        slide: 0,
        sliding: null
      }
    },
}
</script>