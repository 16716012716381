<template>

		<div v-if="data" class="container-fluid transitions">
					<img id="top-border" v-bind:src="'/img/border-top.png'">
						<img class="bg-transition" v-bind:src="url_admin+'/images/_transition/'+ data.url_photos">
					<img id="bottom-border" v-bind:src="'/img/border-bottom.png'">

		</div>
</template>

<script>
import axios from 'axios';

export default {
	name: 'TransitionVidéo',
	data () {
		return {
			data: null,
			url_admin: process.env.VUE_APP_ADMIN_URL
		}
	},
	mounted () {
		axios
		.get(process.env.VUE_APP_API_URL+'/getTransitions/3')
		.then(response => (this.data = response.data.data))
	}
}
</script>